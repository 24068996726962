import React from 'react';
import Radium from 'radium';
import { graphql, Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash/get';
import { Fade } from 'react-reveal';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Footer from '../components/footer';
import { isAndAbove, isAndBelow } from '../util';
import Button from '../components/button';
import Map from '../components/map';

const PluginsIndex = props => {
  const plugins = get(props, 'data.allContentfulPlugin.nodes');

  const breakpoints = useBreakpoint();

  const paddingTop = isAndAbove('md', breakpoints) ? 86 : 66;

  let columnCount = 4;
  if (breakpoints.xs) {
    columnCount = 1;
  } else if (isAndAbove('sm', breakpoints) && isAndBelow('md', breakpoints)) {
    columnCount = 2;
  } else if (isAndAbove('lg', breakpoints) && isAndBelow('xl', breakpoints)) {
    columnCount = 3;
  }

  const pluginListRenderOptions = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => <ul className="list-none">{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className="my-1 tracking-wide">{children}</li>
    }
  };

  return (
    <Layout location={props.location} breakpoints={breakpoints}>
      <SEO title="Plugins" />
      <div className="bg-gray-900" style={{ paddingTop: paddingTop }}>
        <div className="bg-gray-100">
          <div className="m-auto px-6 py-12 sm:py-16" style={{ maxWidth: 1280 }}>
            <div className="lg:text-center">
              {/*<h2 className="text-base text-blue font-semibold tracking-wide uppercase">*/}
              {/*  Plug-ins, plug-ins, and more plug-ins*/}
              {/*</h2>*/}
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Detailed Plug-In List
              </p>
            </div>
            <Fade delay={200} duration={2000}>
              <div className="my-12" style={{ columnCount: columnCount, columnGap: '1rem' }}>
                {plugins.map((plugin, index) => (
                  <div key={index} className="mb-6">
                    <div className="text-xl text-blue font-bold uppercase tracking-wide mb-2">{plugin.category}</div>
                    {documentToReactComponents(plugin.list.json, pluginListRenderOptions)}
                  </div>
                ))}
              </div>
            </Fade>
            <div className="flex justify-center">
              <Link to={'/services#equipment'}>
                <Button text="RETURN TO SERVICES" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-900">
        <div
          className="m-auto px-6 pt-12 md:pt-24 pb-8 flex flex-wrap justify-center items-center"
          style={{ maxWidth: 1536 }}>
          <div className="flex flex-col justify-center items-center text-center px-12 md:px-24 py-12">
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">GET STARTED</p>
            <p className="mt-4 max-w-2xl text-xl text-gray-400 lg:mx-auto">
              Contact us today to get started on your next project.
            </p>
            <Button className="mt-4" text="BOOK NOW" />
          </div>
          <div className="flex-grow" style={{ minWidth: breakpoints.xs ? 250 : 450 }}>
            <Map height="350px" />
          </div>
        </div>
      </div>
      <Footer breakpoints={breakpoints} />
    </Layout>
  );
};

export default Radium(PluginsIndex);

export const pageQuery = graphql`
  query PluginsIndexQuery {
    allContentfulPlugin(sort: { fields: order }) {
      nodes {
        category
        list {
          json
        }
      }
    }
  }
`;
